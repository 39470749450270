const homeLoader = () =>
  new Promise((resolve, reject) => {
    const img = new Image();
    img.src =
      window.innerWidth < 900
        ? 'https://res.cloudinary.com/doti9oyov/image/upload/v1709944823/icons/bg_mob_xg4jxl.webp'
        : 'https://res.cloudinary.com/josephkadjsky/image/upload/s--QQ6LmPeI--/v1712094129/banner_pjcgy4.webp';
    img.onload = resolve;
    img.onerror = reject;
  });

export default homeLoader;
