import React from 'react';

// import { Link } from 'react-router-dom';
import {
  Drawer,
  Grid,
  IconButton,
  Typography,
  Link as MuiLink,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { navLinks } from '../constant/navLink';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

export const DrawerBar = (props) => {
  const { t } = useTranslation();

  return (
    <Drawer
      anchor='top'
      variant='temporary'
      {...props}
      PaperProps={{
        sx: {
          backgroundColor: 'white',
          borderBottomLeftRadius: '20px',
          borderBottomRightRadius: '20px',
        },
      }}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
        sx: {
          backdropFilter: 'blur(8px)',
        },
      }}
      sx={{
        display: { xs: 'block', md: 'none' },
      }}
    >
      <Grid container direction='column' pt='20px'>
        <Grid
          item
          container
          justifyContent='flex-end'
          alignItems='flex-end'
          pr={3}
        >
          <IconButton>
            <CloseIcon color='primary' />
          </IconButton>
        </Grid>
        <Grid
          item
          container
          direction='column'
          gap={3}
          sx={{
            padding: '20px 40px 40px',
            alignContent: { sm: 'flex-start' },
          }}
        >
          {navLinks.map((page) => (
            <MuiLink
              href={page.link}
              key={page.name}
              underline='none'
              // component={Link}
              color='primary.main'
            >
              <Grid container>
                <Typography textAlign='center' fontWeight='bold'>
                  {t(page.name)}
                </Typography>
              </Grid>
            </MuiLink>
          ))}
          <MuiLink
            // component={Link}
            href='#contact'
            underline='none'
            aria-label='contact us'
            textTransform='capitalize'
            textAlign='center'
            color='light.main'
            sx={{
              padding: '20px 25px',
              backgroundColor: 'primary.light',
              borderRadius: '30px',
            }}
          >
            Contact us
          </MuiLink>
        </Grid>
      </Grid>
    </Drawer>
  );
};

DrawerBar.propTypes = {
  onClick: PropTypes.func,
};
