import { createTheme } from '@mui/material/styles';

// Defining a customer theme

const defaultTheme = createTheme();

const theme = createTheme(defaultTheme, {
  direction: 'ltr',
  palette: {
    mode: 'light',
    primary: {
      main: '#032842',
      light: '#2D77AB',
    },
    secondary: {
      main: '#000000',
      light: '#767676',
    },
    text: {
      fontFamily: 'noto sans',
      primary: '#032842',
      secondary: '#000000',
      disabled: 'gray',
    },
    light: {
      main: 'white',
    },
    background: {
      default: '#FFF',
      paper: '#C9C9C9',
    },
  },
  transitions: {
    easing: {
      easeInOut: 'cubic-bezier(0.63, 0.24, 0.35, 0.71)',
    },
  },

  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          h1Caption: 'span',
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#FFF',
        },
      },
    },

    MuiLink: {
      variants: [
        {
          props: { variant: 'navLink' },
          style: {
            fontSize: '14px',
            textTransform: 'none',
            fontWeight: 300,
          },
        },
      ],
    },

    MuiMenu: {
      styleOverrides: {
        list: {
          '&[role="menu"]': {
            backgroundColor: '#FFF',
            borderRadius: '10px',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: 'noto sans',
          fontStyle: 'normal',
          fontWeight: 'normal',
          fontSize: '14px',
          textDecoration: 'capitilize',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontFamily: 'noto sans',
          fontStyle: 'normal',
          fontWeight: 'normal',
          fontSize: '14px',
          paddingTop: '2px',
          paddingBottom: '7px',
          borderRadius: '10px',
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'noto sans';
          font-style: normal;
          font-display: swap;
          font-weight: normal;
        }
        body {
            margin: 0;
            display: flex;
            -webkit-box-flex-wrap: wrap;
            -webkit-flex-wrap: wrap;
            -webkit-flex-direction: column;
            -ms-flex-direction: column;
            flex-direction: column;
          }
        ::-webkit-scrollbar {
            display: none;
        }
      `,
    },
  },
  typography: {
    h1: {
      fontFamily: 'noto sans',
      fontStyle: 'normal',
      fontWeight: 'extra-bold',
      fontSize: '45.78px',
      lineHeight: '1.36',
      [defaultTheme.breakpoints.between('xs', 'sm')]: {
        fontSize: '40.78px',
      },
      [defaultTheme.breakpoints.up('md')]: {},
    },
    h2: {
      fontFamily: 'noto sans',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '39.81px',
      [defaultTheme.breakpoints.between('xs', 'sm')]: {
        fontSize: '30.81px',
      },
      [defaultTheme.breakpoints.up('md')]: {},
    },
    h3: {
      fontFamily: 'noto sans',
      fontStyle: 'normal',
      fontWeight: 'semibold',
      fontSize: '33.18px',
      [defaultTheme.breakpoints.between('xs', 'sm')]: {},
      [defaultTheme.breakpoints.up('md')]: {},
    },
    h4: {
      fontFamily: 'noto sans',
      fontStyle: 'normal',
      fontWeight: 'regular',
      fontSize: '27.65px',
      [defaultTheme.breakpoints.between('xs', 'sm')]: {},
      [defaultTheme.breakpoints.up('md')]: {},
    },
    h5: {
      fontFamily: 'noto sans',
      fontSize: '23px',
      fontStyle: 'medium',
      fontWeight: 'normal',
      [defaultTheme.breakpoints.between('xs', 'sm')]: {},
      [defaultTheme.breakpoints.up('md')]: {},
    },
    h6: {
      fontFamily: 'noto sans',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '19px',
      [defaultTheme.breakpoints.between('xs', 'sm')]: {},
      [defaultTheme.breakpoints.up('md')]: {},
    },
    body1: {
      fontFamily: 'noto sans',
      fontStyle: 'normal',
      fontSize: '16px',
      fontWeight: 300,
      whiteSpace: 'pre-line',
      [defaultTheme.breakpoints.down('sm')]: {
        textAlign: 'left',
      },
      [defaultTheme.breakpoints.up('md')]: {},
      // lineHeight: '1.6rem',
    },
    body2: {
      fontFamily: 'noto sans',
      fontStyle: 'normal',
      fontWeight: 300,
      fontSize: '14px',
      whiteSpace: 'pre-line',
      [defaultTheme.breakpoints.between('xs', 'sm')]: {},
      [defaultTheme.breakpoints.up('md')]: {},
      // lineHeight: '1.6rem',
    },
    subtitle1: {
      fontFamily: 'noto sans',
      fontStyle: 'normal',
      fontWeight: 'regular',
      fontSize: '16px',
      [defaultTheme.breakpoints.between('xs', 'sm')]: {},
      [defaultTheme.breakpoints.up('md')]: {},
      // lineHeight: '1.6rem',
    },
    subtitle2: {
      fontFamily: 'noto sans',
      fontStyle: 'normal',
      fontWeight: 300,
      fontSize: '14px',
      [defaultTheme.breakpoints.between('xs', 'sm')]: {},
      [defaultTheme.breakpoints.up('md')]: {},
      // lineHeight: '1.6rem',
    },
    caption: {
      fontFamily: 'noto sans',
      fontStyle: 'normal',
      fontWeight: 'light',
      fontsize: '11px',
      [defaultTheme.breakpoints.between('xs', 'sm')]: {},
      [defaultTheme.breakpoints.up('md')]: {},
      // lineHeight: '1.6rem',
    },
    h1Caption: {
      fontFamily: 'noto sans',
      fontStyle: 'normal',
      fontWeight: '100',
      fontSize: 'inherit',
      lineHeight: '1.06',
    },
  },
});

export default theme;
