import React from 'react';
import { Grid } from '@mui/material';

const Loader = () => (
	<Grid
		container
		justifyContent="center"
		alignContent="center"
		sx={{ minHeight: '100svh' }}
	>
		loading...
	</Grid>
);
export default Loader;
