import React from 'react';
import { Grid, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const Footer = () => {
  const { t } = useTranslation();
  return (
    <Grid
      container
      component='footer'
      sx={{
        backgroundColor: '#F3F6FA',
      }}
    >
      <Grid
        item
        container
        xs={11}
        xl={6}
        sx={{
          justifyContent: { xs: 'center', sm: 'space-between' },
          marginInline: 'auto',
          padding: '20px 0px',
        }}
      >
        <Grid item>
          <Typography
            variant='caption'
            component={Link}
            to='/privacy'
            sx={{
              textDecoration: 'none',
            }}
          >
            {t('Privacy policy')}
          </Typography>
          |
          <Typography variant='caption'>
            &copy; InspectFleet {new Date().getFullYear()}.{' '}
            {t('All right reserved')}
          </Typography>
        </Grid>
        <Typography variant='caption'>
          {t('Designed and developed by')} &nbsp;
          <Typography
            component='a'
            variant='caption'
            color='secondary.light'
            href='https://kilamaelie.com'
            rel='noopener noreferrer'
            target='_blank'
            sx={{
              textDecoration: 'none',
            }}
          >
            Kilama Elie
          </Typography>
        </Typography>
      </Grid>
    </Grid>
  );
};
