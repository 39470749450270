export const navLinks = [
  {
    id: 1,
    name: 'Home',
    link: '#home',
  },
  {
    id: 2,
    name: 'About',
    link: '#about',
  },

  {
    id: 3,
    name: 'Services',
    link: '#services',
  },
];
