import React from 'react';
import { Outlet } from 'react-router-dom';
import { Navbar as NavBar } from './navbar';
import { Footer } from './footer';

const Layout = () => {
	return (
		<>
			<NavBar />
			<Outlet />
			<Footer />
		</>
	);
};

export default Layout;
