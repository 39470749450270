import React from 'react';
import { Suspense, lazy } from 'react';
import { Routes, Route } from 'react-router-dom';
import Loader from '../src/common/component/loader';
import RouteLayout from '../src/common/component/routeLayout';
import homeLoader from '../src/common/utilities/loader';

const App = () => {
  const Home = lazy(() => import('../src/pages/home'));
  const NoMatch = lazy(() => import('../src/common/component/noMatch'));
  const Privacy = lazy(() => import('../src/pages/privacypolicy'));

  const ElementSuspense = (Children) => (
    <Suspense fallback={<Loader />}>{Children}</Suspense>
  );
  return (
    <>
      <Routes>
        <Route element={<RouteLayout />} path='/' loader={homeLoader}>
          <Route element={ElementSuspense(<Home />)} index />
          <Route path='/privacy' element={ElementSuspense(<Privacy />)} />
        </Route>
        <Route path='*' element={ElementSuspense(<NoMatch />)} />
      </Routes>
    </>
  );
};

export default App;
